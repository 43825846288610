import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMDXComponents as _provideComponents } from "@mdx-js/react";
import { isSellingLive } from '@/utils/is-selling-live';
import Balancer from 'react-wrap-balancer';
import SeaOfRedLines from '../../public/assets/landing/bg-sea-of-red-lines@2x.png';
import TSErrors from '../../public/assets/landing/ts-errors@2x.png';
import HandPointingDownSparkles from '../../public/assets/landing/hand-pointing-down-sparkles@2x.png';
import ExerciseScreenshots from '../../public/assets/landing/exercise-screenshots@2x.png';
import Cauldron from '../../public/assets/landing/cauldron@2x.png';
import Compass from '../../public/assets/landing/compass@2x.png';
import Wand from '../../public/assets/landing/wand@2x.png';
import FiveStarRating from '../../public/assets/landing/5-star-rating@2x.png';
import Shine from '../../public/assets/landing/shine@2x.png';
import MattPocock from '../../public/assets/landing/matt-pocock@2x.png';
import { motion } from 'framer-motion';
function _createMdxContent(props) {
    const _components = Object.assign({
        div: "div",
        section: "section",
        p: "p",
        br: "br",
        strong: "strong",
        h2: "h2",
        i: "i",
        span: "span",
        h3: "h3",
        ul: "ul",
        li: "li",
        a: "a",
        svg: "svg",
        path: "path",
        code: "code"
    }, _provideComponents(), props.components), { ErrorFromHell, Image, Testimonial, Module } = _components;
    if (!ErrorFromHell) _missingMdxReference("ErrorFromHell", true);
    if (!Image) _missingMdxReference("Image", true);
    if (!Testimonial) _missingMdxReference("Testimonial", true);
    if (!Module) _missingMdxReference("Module", true);
    return _jsxs(_Fragment, {
        children: [
            _jsx("div", {
                className: "h-20 w-full bg-gradient-to-b from-background to-[#191427] md:h-24",
                "aria-hidden": "true"
            }),
            "\n",
            _jsxs("section", {
                className: "bg-gradient-to-t from-background selection:bg-[#CC5344] selection:text-white to-[#191427] via-[#191427] relative",
                children: [
                    _jsxs("div", {
                        className: "relative z-10 overflow-x-hidden",
                        children: [
                            _jsx("div", {
                                className: "md:pt-32 md:pb-64 pt-0 pb-16",
                                children: _jsxs("div", {
                                    className: "not-prose md:scale-150 flex flex-col gap-8 text-center font-heading font-normal text-transparent bg-clip-text bg-gradient-to-b from-[#866583] to-white",
                                    children: [
                                        _jsx("p", {
                                            className: "text-lg",
                                            children: "The feature’s working."
                                        }),
                                        "\n",
                                        _jsx("p", {
                                            className: "text-xl",
                                            children: "The tests are passing."
                                        }),
                                        "\n",
                                        _jsx("p", {
                                            className: "text-2xl",
                                            children: "But your IDE is a sea of red lines."
                                        }),
                                        "\n",
                                        _jsx("p", {
                                            className: "text-3xl font-semibold text-balance",
                                            children: "TypeScript’s not happy again."
                                        })
                                    ]
                                })
                            }),
                            _jsxs(_components.div, {
                                className: "flex text-[#CCC4E0] flex-col items-center text-center not-prose gap-5",
                                children: [
                                    _jsx(_components.p, {
                                        children: "You move on to the next error:"
                                    }),
                                    _jsx(_components.p, {
                                        className: "font-heading md:text-5xl text-balance text-3xl font-semibold text-white",
                                        children: "“What on earth is this?”"
                                    }),
                                    _jsx(_components.p, {
                                        children: "you think to yourself."
                                    })
                                ]
                            }),
                            _jsxs(ErrorFromHell, {
                                children: [
                                    `Element implicitly has an ‘any’ type because expression of type ‘string’ can’t be used to index type ‘{ string: string | undefined; }’`,
                                    _jsx("br", {}),
                                    _jsx("br", {}),
                                    `No index signature with a parameter of type ‘string’ was found on type ‘{ string: string | undefined; }’`
                                ]
                            }),
                            _jsxs(_components.div, {
                                className: "not-prose flex flex-col w-full items-center gap-5 text-center justify-center mt-16 text-white mx-auto",
                                children: [
                                    _jsxs(_components.p, {
                                        className: "text-balance max-w-2xl [&_strong]:font-semibold",
                                        children: [
                                            _jsx(_components.strong, {
                                                children: "It’s impossible to Google."
                                            }),
                                            " Every search result is either",
                                            ' ',
                                            _jsx(_components.strong, {
                                                children: '"here’s 100 lines of complex code"'
                                            }),
                                            " or",
                                            ' ',
                                            _jsx(_components.strong, {
                                                children: "\"here's a solution that doesn't work”"
                                            })
                                        ]
                                    }),
                                    _jsx(_components.p, {
                                        className: "text-[#CCC4E0] text-balance py-3 italic",
                                        children: "You contemplate the absurd amount of hours you spend solving these problems."
                                    }),
                                    _jsx(_components.p, {
                                        className: "",
                                        children: "You'd rather give up than deal with another:"
                                    })
                                ]
                            }),
                            _jsx(Image, {
                                src: TSErrors,
                                width: 756,
                                height: 483,
                                className: "relative mx-auto mb-0 -translate-y-10 md:-translate-y-16",
                                alt: "TypeScript Error: The intersection 'User & PublicUser' was reduced to 'never' because property 'email' has conflicting types in some constituents."
                            }),
                            _jsxs(_components.h2, {
                                className: "not-prose mb-16 flex flex-col text-center font-heading md:text-5xl text-4xl font-normal leading-tight",
                                children: [
                                    _jsx(_components.i, {
                                        className: "text-[#CCC4E0] scale-75 pb-2",
                                        children: "Your time is precious."
                                    }),
                                    _jsx(_components.strong, {
                                        className: "font-semibold text-balance",
                                        children: "You have bigger fish to fry."
                                    })
                                ]
                            }),
                            _jsx(_components.p, {
                                children: "You could spend that time building new features or bugfixes. You could be providing value. Instead, you're fighting TypeScript."
                            }),
                            _jsx(_components.p, {
                                children: "These days, TypeScript is an industry default. If you’re starting an enterprise app today, you’ll need a good reason NOT to use TypeScript."
                            }),
                            _jsx(_components.p, {
                                children: "So you keep on pushing - all the time feeling like you’re working against TypeScript, not with it."
                            }),
                            _jsx(_components.p, {
                                children: "The truth is that you never truly learned TypeScript, at least not in depth. That's the source of your pain."
                            }),
                            _jsx(_components.p, {
                                children: "You never developed a systematic approach to solving type errors."
                            }),
                            _jsxs(_components.p, {
                                children: [
                                    "You never developed a mental model for understanding when to use ",
                                    _jsx("span", {
                                        className: "text-rose-400",
                                        children: "any"
                                    }),
                                    "."
                                ]
                            }),
                            _jsx(_components.p, {
                                children: "You never understood the power of generics."
                            }),
                            _jsx(_components.p, {
                                children: "Ultimately…"
                            }),
                            _jsx(_components.p, {
                                children: _jsx(_components.strong, {
                                    children: "You never learned the underlying principles and patterns of being an effective TypeScript engineer."
                                })
                            })
                        ]
                    }),
                    _jsx(Image, {
                        alt: "sea of red lines",
                        "aria-hidden": "true",
                        src: SeaOfRedLines,
                        fill: true,
                        className: "object-top object-contain mix-blend-lighten z-0"
                    })
                ]
            }),
            "\n",
            _jsxs("section", {
                className: "bg-background pb-24",
                id: "approach",
                "aria-label": "approach",
                children: [
                    _jsxs("div", {
                        className: "not-prose flex w-full flex-col items-center",
                        children: [
                            _jsx(Image, {
                                "aria-hidden": "true",
                                src: HandPointingDownSparkles,
                                alt: "hand pointing down with sparkles",
                                width: 512,
                                height: 633,
                                className: "mx-auto -mt-10"
                            }),
                            _jsx("h2", {
                                className: "text-center max-w-screen-lg text-balance font-heading md:text-6xl sm:text-5xl text-4xl font-normal pb-5",
                                children: "An exercise-driven approach for learning TypeScript"
                            }),
                            "\n",
                            _jsx("h3", {
                                className: "text-center text-primary max-w-screen-lg text-balance font-text text-xl font-normal",
                                children: "Total TypeScript's approach is different than what you'll find in other online courses."
                            }),
                            _jsx(Image, {
                                src: ExerciseScreenshots,
                                className: "flex sm:min-w-full min-w-[550px] sm:rotate-0 -rotate-6",
                                width: 1276,
                                height: 913,
                                alt: "Total TypeScript screenshots"
                            })
                        ]
                    }),
                    _jsx(_components.p, {
                        children: "Instead of sitting through hours of lectures, you'll be presented with problematic code and a concise explanation of what needs to be done."
                    }),
                    _jsx(_components.p, {
                        children: "Then using your existing skills along helpful links to relevant docs and other resources, you'll have as much time as you need to complete the challenge either in your local editor or using the editor embedded into each lesson."
                    }),
                    _jsx(_components.p, {
                        children: "When you're ready, you can watch how a true TypeScript Wizard approaches solving the challenge."
                    }),
                    _jsx(_components.p, {
                        children: "The exercises build upon another, demonstrating the most important TypeScript concepts in a variety different contexts so you can build understanding of when, where, and how to best apply them in your own application and library code."
                    })
                ]
            }),
            "\n",
            _jsx("section", {
                className: "bg-[#07090F] flex items-center",
                children: _jsxs("div", {
                    className: "max-w-screen-lg sm:py-24 py-16 md:flex-row flex-col flex w-full mx-auto relative",
                    children: [
                        _jsx(Image, {
                            src: Cauldron,
                            alt: "a cauldron with TS written on it",
                            width: 400,
                            height: 400,
                            "aria-hidden": "true",
                            className: "md:absolute md:mt-0 -mt-48 xl:max-w-full sm:max-w-[300px] mx-auto max-w-[300px] right-0 xl:-top-40 sm:-top-32 md:-rotate-12"
                        }),
                        _jsxs("div", {
                            className: "",
                            children: [
                                _jsxs("h2", {
                                    className: "px-5 mb-12 not-prose md:text-left text-center md:text-5xl sm:text-4xl text-3xl font-medium font-heading flex flex-col",
                                    children: [
                                        _jsx("span", {
                                            children: "Make TypeScript work "
                                        }),
                                        _jsx("span", {
                                            className: "italic",
                                            children: "for you, not against you"
                                        })
                                    ]
                                }),
                                _jsx(_components.p, {
                                    children: "What’s the thing that separates TypeScript wizards from the rest?"
                                }),
                                _jsx(_components.p, {
                                    children: "Understanding."
                                }),
                                _jsx(_components.p, {
                                    children: "To them, TypeScript doesn’t feel like magic. It feels simple, predictable and malleable."
                                }),
                                _jsx(_components.p, {
                                    children: "They can bend it to their will, and use its power for amazing DX."
                                }),
                                _jsx(_components.p, {
                                    children: "Total TypeScript is all about building that understanding at a deep level."
                                }),
                                _jsx(_components.p, {
                                    children: "When you’ve completed the exercises, you’ll reach a point of mastery where very little surprises you any more."
                                }),
                                _jsx(_components.p, {
                                    children: "You’ll have less fear when approaching nasty TypeScript errors, more confidence when typing dynamic signatures, gain velocity by cutting lines of code, and so much more."
                                })
                            ]
                        })
                    ]
                })
            }),
            "\n",
            _jsxs("section", {
                className: "bg-[#180F24] px-3 flex flex-col justify-center relative items-center",
                children: [
                    _jsxs("div", {
                        className: "max-w-screen-lg sm:py-24 pb-16 flex md:flex-row flex-col w-full mx-auto items-start relative",
                        children: [
                            _jsx(Image, {
                                src: Compass,
                                alt: "",
                                width: 540,
                                height: 540,
                                className: "relative sm:-translate-y-32 sm:mt-10 mb-0 -mt-24"
                            }),
                            _jsxs("div", {
                                className: "md:pr-10",
                                children: [
                                    _jsxs("h2", {
                                        className: "px-5 text-balance mb-12 not-prose md:text-left text-center sm:text-5xl text-4xl font-medium font-heading",
                                        children: [
                                            _jsx(_components.strong, {
                                                children: "You deserve"
                                            }),
                                            " so much better"
                                        ]
                                    }),
                                    _jsx(_components.p, {
                                        children: "You deserve a practical, systematic approach to learning TypeScript in depth."
                                    }),
                                    _jsx(_components.p, {
                                        children: "Total TypeScript shows you the techniques used by the most complex OSS libraries."
                                    }),
                                    _jsx(_components.p, {
                                        children: "Become your company’s resident TypeScript Wizard."
                                    }),
                                    _jsxs(_components.ul, {
                                        children: [
                                            "\n",
                                            _jsx(_components.li, {
                                                children: "You will become a better contributor and reviewer."
                                            }),
                                            "\n",
                                            _jsx(_components.li, {
                                                children: "You will be the one to unblock others and raise their velocity."
                                            }),
                                            "\n",
                                            _jsx(_components.li, {
                                                children: "You will be the one who knows instinctively what TypeScript is doing."
                                            }),
                                            "\n"
                                        ]
                                    }),
                                    _jsx(_components.p, {
                                        children: "You are indispensable."
                                    })
                                ]
                            })
                        ]
                    }),
                    _jsx("div", {
                        className: "bg-gradient-to-t from-white/5 to-white/10 border-t border-x rounded-t-lg border-white/10 sm:px-10 px-2 sm:pb-16 pb-24",
                        children: _jsxs("div", {
                            className: "flex md:flex-row flex-col-reverse max-w-screen-lg mx-auto w-full items-start",
                            children: [
                                _jsxs("div", {
                                    children: [
                                        _jsx("h3", {
                                            className: "not-prose mb-8 text-balance px-5 md:pt-20 pt-0 md:mt-0 -mt-48 text-center font-heading text-4xl font-semibold md:text-left",
                                            children: _jsx(_components.p, {
                                                children: "Who is Total TypeScript for?"
                                            })
                                        }),
                                        _jsx(_components.p, {
                                            children: "Total TypeScript is suitable for any developer who is ready to work at becoming a wizard, though some basic familiarity with TypeScript is recommended."
                                        }),
                                        _jsx(_components.p, {
                                            children: "The Type Transformations workshop begins by warming you up with exercises on basic inference and essential types."
                                        }),
                                        _jsx(_components.p, {
                                            children: "Then nearly 150 exercises later, the Advanced TypeScript Patterns workshop will have you creating chainable method abstractions with generics and the builder pattern."
                                        }),
                                        _jsxs(_components.p, {
                                            children: [
                                                "Check out the free ",
                                                _jsx(_components.a, {
                                                    href: "/tutorials/beginners-typescript",
                                                    children: "Beginner's Tutorial"
                                                }),
                                                " to set a foundation and get an idea for the workshop's teaching approach."
                                            ]
                                        })
                                    ]
                                }),
                                _jsx(Image, {
                                    src: Wand,
                                    alt: "a wand",
                                    width: 444,
                                    height: 586,
                                    className: "relative md:-translate-y-28 md:mt-0 -mt-16"
                                })
                            ]
                        })
                    })
                ]
            }),
            "\n",
            _jsx("section", {
                id: "feedback",
                className: "bg-gradient-to-b from-black relative to-background w-full sm:pb-24 pb-5 pt-10 border-x",
                children: _jsxs("div", {
                    className: "not-prose flex flex-col items-center w-full",
                    children: [
                        _jsx("svg", {
                            className: "absolute top-[-60px]",
                            width: "1550",
                            height: "61",
                            viewBox: "0 0 1550 61",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                            children: _jsx("path", {
                                d: "M0 60.35L775 0L1550 60.35H0Z",
                                fill: "#000"
                            })
                        }),
                        _jsx(Image, {
                            src: FiveStarRating,
                            alt: "5-star rating",
                            width: 110,
                            height: 37,
                            className: "sm:mb-16 mb-10"
                        }),
                        _jsx("h2", {
                            className: "px-5 text-balance sm:mb-24 mb-16 text-center lg:text-5xl sm:text-4xl text-3xl font-medium font-heading",
                            children: "Feedback for Total TypeScript"
                        }),
                        _jsxs("div", {
                            className: "grid origin-top md:grid-cols-2 grid-cols-1 md:gap-16 sm:gap-10 gap-0 max-w-screen-lg w-full mx-auto px-5",
                            children: [
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Tomasz Łakomy',
                                        image: require('../../public/testimonials/tomasz-lakomy.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "Total TypeScript by Matt Pocock is a masterpiece, 100% worth the money."
                                    })
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Alex Tana',
                                        image: require('../../public/testimonials/alex-tana.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "Total TypeScript is hands down the best course out there, and the format is the best I've seen. It feels like you're doing a 1 on 1 with Matt Pocock."
                                    })
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'John D. Jameson',
                                        image: require('../../public/testimonials/john-d-jameson.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "I’m really digging Total TypeScript by Matt Pocock. It’s taking all the random TypeScript knowledge I’ve found in docs and Stack Overflow over the years and showing how it all works under the hood. Highly recommended!"
                                    })
                                }),
                                _jsxs(Testimonial, {
                                    author: {
                                        name: 'Adam Rackis',
                                        image: require('../../public/testimonials/adam-rackis.jpg')
                                    },
                                    children: [
                                        _jsx(_components.p, {
                                            children: "You know those kung fu movies where the hero has a training montage beating the shit out of a tree so he can get good & murder his enemy?"
                                        }),
                                        _jsx(_components.p, {
                                            children: "That's what Matt Pocock's Total TypeScript is like. Except TS instead of Kung Fu. And Jira tickets instead of your enemy. Anyway it's great"
                                        })
                                    ]
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Andrew C.',
                                        image: require('../../public/testimonials/andrew-cherniavskii.jpeg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "The challenges were not too easy, and some of them took me a fair amount of time to find a working solution. This ultimately helped me to better understand TypeScript!"
                                    })
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Mr. Steal Your Code',
                                        image: require('../../public/testimonials/mr-steal-your-code.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "Matt Pocock is saving my life with his Total TypeScript course. Can't tell you how many other videos I've watched that didn't click as much as his have. Looking forward to the more advanced courses."
                                    })
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Christopher Almeida-Symons',
                                        image: require('../../public/testimonials/christopher-almeida-symons.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "After finishing the Total Typescript course by Matt Pocock: I love the UI and how he encourages something a lot of developers are not taught...reading docs. They are a powerful tool and should be encouraged to be used more."
                                    })
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Sebastian Kasanzew',
                                        image: require('../../public/testimonials/sebastian-kasanzew.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "These videos and exercises really helped me to get to the next level in TS. I could improve types in some projects at work right on the next day."
                                    })
                                }),
                                _jsx(Testimonial, {
                                    className: "md:col-span-2",
                                    author: {
                                        name: 'David Stinemetze',
                                        image: require('../../public/testimonials/david-stinemetze.png')
                                    },
                                    children: _jsxs(_components.p, {
                                        children: [
                                            "I’ve just completed the Type Transformations, Generics and Advanced Patterns workshops, and I can say TotalTypeScript is worth every penny. Matt’s mastery of the subject and ability to explain the more complex parts of TypeScript in an understandable way have fundamentally changed and expanded my understanding of the language. While I do think it will take a bit of time to fully process everything I’ve just learned, I definitely feel armed with the knowledge and resources I need to solve ",
                                            _jsx("code", {
                                                className: "!bg-gray-700 rounded !px-1 py-0.5",
                                                children: "any"
                                            }),
                                            " TypeScript problem in front of me. Thank you, Matt for all of the work you put into this!"
                                        ]
                                    })
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Jökull Sólberg',
                                        image: require('../../public/testimonials/jokull-solberg.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "The content itself was incredible. Videos are superb. Delivery, pacing, tone, everything- wow. I feel like I have superpowers. I’ve Already made one library available!"
                                    })
                                }),
                                _jsx(Testimonial, {
                                    author: {
                                        name: 'Rares Golea',
                                        image: require('../../public/testimonials/rares-golea.jpg')
                                    },
                                    children: _jsx(_components.p, {
                                        children: "I truly feel like a wizard and I can’t stop recommending it!"
                                    })
                                })
                            ]
                        })
                    ]
                })
            }),
            "\n",
            _jsxs("section", {
                id: "about",
                className: "sm:text-lg text-base relative flex flex-col items-center border-t border-white/5 bg-background pt-24",
                children: [
                    _jsx("div", {
                        className: "absolute w-1/2 h-px top-0 bg-gradient-to-r from-transparent via-primary to-transparent opacity-20",
                        "aria-hidden": "true"
                    }),
                    _jsx("div", {
                        className: "absolute w-1/2 h-px top-px bg-gradient-to-r from-transparent via-black to-transparent opacity-75",
                        "aria-hidden": "true"
                    }),
                    _jsx(Image, {
                        src: Shine,
                        alt: "shine",
                        width: 1006,
                        height: 588,
                        "aria-hidden": "true",
                        className: "not-prose absolute top-0 pointer-events-none select-none"
                    }),
                    _jsxs("h2", {
                        className: "not-prose flex flex-col items-center font-heading md:text-6xl text-5xl text-white",
                        children: [
                            _jsx("span", {
                                className: "text-3xl text-primary",
                                children: "Total"
                            }),
                            "\n",
                            _jsx("span", {
                                className: "font-medium",
                                children: "TypeScript"
                            })
                        ]
                    }),
                    _jsxs("div", {
                        className: "sm:py-24 py-10 flex md:flex-row flex-col max-w-screen-lg gap-5",
                        children: [
                            _jsxs("div", {
                                className: "[&_li>p]:pl-0",
                                children: [
                                    _jsx(_components.p, {
                                        children: "Total TypeScript contains five professional-grade workshops for you to achieve TypeScript Wizardry:"
                                    }),
                                    _jsxs(_components.ul, {
                                        children: [
                                            "\n",
                                            _jsxs(_components.li, {
                                                children: [
                                                    "\n",
                                                    _jsx(_components.p, {
                                                        children: "TypeScript Pro Essentials (200+ exercises, 17 sections)"
                                                    }),
                                                    "\n"
                                                ]
                                            }),
                                            "\n",
                                            _jsxs(_components.li, {
                                                children: [
                                                    "\n",
                                                    _jsx(_components.p, {
                                                        children: "Type Transformations (50+ exercises, 7 sections)"
                                                    }),
                                                    "\n"
                                                ]
                                            }),
                                            "\n",
                                            _jsxs(_components.li, {
                                                children: [
                                                    "\n",
                                                    _jsx(_components.p, {
                                                        children: "TypeScript Generics (40+ exercises, 6 sections)"
                                                    }),
                                                    "\n"
                                                ]
                                            }),
                                            "\n",
                                            _jsxs(_components.li, {
                                                children: [
                                                    "\n",
                                                    _jsx(_components.p, {
                                                        children: "Advanced TypeScript Patterns (40+ exercises, 7 sections)"
                                                    }),
                                                    "\n"
                                                ]
                                            }),
                                            "\n",
                                            _jsxs(_components.li, {
                                                children: [
                                                    "\n",
                                                    _jsx(_components.p, {
                                                        children: "Advanced React with TypeScript (50+ exercises, 6 sections)"
                                                    }),
                                                    "\n"
                                                ]
                                            }),
                                            "\n"
                                        ]
                                    }),
                                    _jsx(_components.p, {
                                        children: "Each workshop contains dozens of exercises designed to put your problem solving skills to the test."
                                    })
                                ]
                            }),
                            _jsxs("div", {
                                children: [
                                    _jsxs("div", {
                                        className: "flex items-center gap-5 px-5",
                                        children: [
                                            _jsx(Image, {
                                                src: MattPocock,
                                                className: "flex-shrink-0 rounded sm:my-0 my-0",
                                                alt: "Matt Pocock",
                                                width: 139,
                                                height: 116
                                            }),
                                            _jsx("span", {
                                                className: "text-balance font-text md:text-4xl text-3xl text-white",
                                                children: "Hi, I’m Matt Pocock"
                                            })
                                        ]
                                    }),
                                    _jsx(_components.p, {
                                        children: "Before creating Total TypeScript, I was a member of the XState core team and worked as a developer advocate at Vercel."
                                    }),
                                    _jsx(_components.p, {
                                        children: "I've worked with TypeScript as a lead fullstack dev, a library maintainer, and now as a full-time educator."
                                    }),
                                    _jsx(_components.p, {
                                        children: "I built Total TypeScript to make the secrets of open-source maintainers and industry experts available to everyone."
                                    })
                                ]
                            })
                        ]
                    })
                ]
            }),
            "\n",
            _jsxs("section", {
                id: "workshops",
                className: "bg-gradient-to-b from-background to-black/40 border-b border-white/5 w-full flex flex-col",
                children: [
                    _jsx("h3", {
                        className: "font-medium not-prose text-2xl pb-10 px-5 text-center max-w-screen-lg mx-auto text-foreground w-full",
                        children: "Workshops Include"
                    }),
                    _jsxs("div", {
                        className: "flex flex-col gap-20 pb-24",
                        children: [
                            _jsxs(Module, {
                                title: "Type Transformations",
                                slug: "type-transformations",
                                metaLabel: "over 50 lessons",
                                sub: "Wield the power of transformation to move from one type to another with ease",
                                image: "https://cdn.sanity.io/images/z9io1e0u/production/8d26aee5def2397f419bb038fd8879286fd41bda-768x768.png",
                                children: [
                                    _jsx(_components.p, {
                                        children: "Many problems in TypeScript come down to transforming types into other types."
                                    }),
                                    _jsx(_components.p, {
                                        children: "This workshop is your first step on the path to wizardry with topics including unions, template literals, conditional types and mapped types. You'll also see how to take advantage of inference and type helpers to ensure you retain flexibility while ensuring your types are exactly how you expect them to be."
                                    })
                                ]
                            }),
                            _jsxs(Module, {
                                title: "TypeScript Generics",
                                slug: "typescript-generics",
                                metaLabel: "49 lessons",
                                sub: "Unlock amazing inference in the functions you create and the abstractions you build",
                                image: "https://cdn.sanity.io/images/z9io1e0u/production/12ef51ed533404ccf132765c75be8b6fe600bcda-769x769.png",
                                children: [
                                    _jsx(_components.p, {
                                        children: "Generics are one of TypeScript's most complex and intimidating features."
                                    }),
                                    _jsx(_components.p, {
                                        children: "Getting good at generics requires deep knowledge of the TypeScript Compiler, as well as an understanding of how their placement impacts their usefulness."
                                    }),
                                    _jsx(_components.p, {
                                        children: "You'll practice with using generics in a variety of situations, as well as how to use function overloads when generics aren't the best fit."
                                    })
                                ]
                            }),
                            _jsxs(Module, {
                                title: "Advanced TypeScript Patterns",
                                slug: "advanced-typescript-patterns",
                                metaLabel: "45 lessons",
                                sub: "Become fluent in TypeScript's undocumented patterns",
                                image: "https://cdn.sanity.io/images/z9io1e0u/production/ce94d457bbfa7580dd803aaa5630a7c6ebed9fee-927x927.png",
                                children: [
                                    _jsx(_components.p, {
                                        children: "The TypeScript community has discovered and iterated upon several patterns that build on the type transformation and generics techniques explored in the earlier workshops."
                                    }),
                                    _jsx(_components.p, {
                                        children: "Here you'll grow more powerful through practice with branded types and the builder pattern, among others. You will also discover the best practices for strongly typing the global scope and how to understand the flow of generics through external libraries."
                                    })
                                ]
                            }),
                            _jsxs(Module, {
                                title: "Advanced React with TypeScript",
                                slug: "advanced-react-with-typescript",
                                metaLabel: "57 lessons",
                                sub: "Expert-level knowledge for typing your React apps with TypeScript",
                                image: "https://cdn.sanity.io/images/z9io1e0u/production/bb6a657e3e8d8a8dc4d66783d23a56797b22b882-1200x1200.png",
                                children: [
                                    _jsx(_components.p, {
                                        children: "Using TypeScript with React can be tricky, requiring flexible, type-safe, and structured components."
                                    }),
                                    _jsx(_components.p, {
                                        children: "This workshop empowers developers to combine React and TypeScript effectively. It covers advanced props handling, leveraging TypeScript generics for components and hooks, safer React hooks using types, understanding the TypeScript type system, recognizing industry-standard patterns, and safely integrating external libraries into React components."
                                    })
                                ]
                            }),
                            _jsxs(Module, {
                                title: "TypeScript Pro Essentials",
                                slug: "typescript-pro-essentials",
                                metaLabel: "220 lessons",
                                sub: "Everything you need from setup to application development patterns",
                                image: "https://cdn.sanity.io/images/z9io1e0u/production/80da574ffbb12904f823723d5ea0a8e20add0270-1200x1200.png",
                                children: [
                                    _jsx(_components.p, {
                                        children: "Whether you're new to the language or looking to reenforce your foundation, this workshop covers all of the essential TypeScript knowledge and skills you need."
                                    }),
                                    _jsx(_components.p, {
                                        children: "From setting up your dev environment through best practices for designing types, this workshop bridges the gap between TypeScript theory and practice with type manipulation techniques, OOP with classes, annotations, assertions, and more!"
                                    })
                                ]
                            })
                        ]
                    })
                ]
            })
        ]
    });
}
function MDXContent(props = {}) {
    const { wrapper: MDXLayout } = Object.assign({}, _provideComponents(), props.components);
    return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
        children: _jsx(_createMdxContent, props)
    })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
    throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
